import React from 'react';
import DefaultLayout from '../components/DefaultLayout';
import ConvertkitButton from '../components/ConvertkitButton';
import ConvertKit from '../components/ConvertKit';
import FeaturesGrid from '../components/FeaturesGrid';

interface Props {}

const SaasCompanies: React.FC = () => {
  return (
    <DefaultLayout>
      <div className='container mx-auto px-6 lg:px-20 '>
        <div className='entry-content prose max-w-none mt-0 lg:mt-8 lg:mb-16'>
          <h2>Your expert SaaS accountant.</h2>
          <p>
            If you're like most SaaS companies, you're always looking for ways to accelerate growth and reduce churn.
            And that's where we come in. Argento CPA is a full-service accounting firm that specializes in working with
            cloud-based businesses. From bookkeeping and accounting to virtual CFO services, we can help you take your
            company to the next level.
          </p>
          <p>
            What sets us apart from other accounting firms is our deep understanding of the unique challenges faced by
            SaaS companies. We know that your business model is constantly evolving, and we adjust our services
            accordingly. Whether you're just starting out or you're ready to scale up, we can help you reach your goals.
          </p>
          <p>How we can help:</p>
          <ul>
            <li>EBITDA – know the value of your business</li>
            <li>Tax returns – filed on time and accurately</li>
            <li>SRED and other claims – organize your chart of accounts so you can maximize your claims</li>
            <li>Payroll - Process pay runs, issue payslips, track timesheets and stat holidays, and payroll tax</li>
            <li>KPI reporting – Track your average contract values, MRR, CAC, and payback periods, and churn rate</li>
          </ul>
          <h3>How our services work for your SaaS company</h3>
          <FeaturesGrid />
          <p>
            So if you're looking for a partner that can help you navigate the ever-changing world of SaaS accounting,
            look no further than Argento CPA. Contact us today to learn more about how we can help your business thrive.
          </p>
        </div>
        {/*TODO: remove this after testing*/}
        {/*<div className='bg-secondary-base h-48 flex flex-col items-center justify-center'>*/}
        {/*  <h2 className='text-white text-center mb-8'>*/}
        {/*    Ready to <strong>get help</strong> with your <strong>bookkeeping</strong>?*/}
        {/*  </h2>*/}
        {/*  <a href='https://info.argentocpa.ca/contact'>*/}
        {/*    <button className='bg-primary-base hover:bg-blue-500 text-white font-bold py-2 px-4 rounded'>*/}
        {/*      Schedule a Call*/}
        {/*    </button>*/}
        {/*  </a>*/}
        {/*</div>*/}
        <div className='entry-content prose max-w-none mt-0 lg:mt-8 lg:mb-16'>
          <ConvertkitButton formkitToggle='e0da3595ff'>Looking for tax tips and tricks?</ConvertkitButton>
          {/*<ConvertKit dataUid='e0da3595ff' />*/}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default SaasCompanies;
