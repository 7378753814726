import React from 'react';
// @ts-ignore
import UpfrontPricing from '../assets/svg/features/UpfrontPricing.inline.svg';
// @ts-ignore
import UnlimitedSupport from '../assets/svg/features/UnlimitedSupport.inline.svg';
// @ts-ignore
import Automation from '../assets/svg/features/Automation.inline.svg';
// @ts-ignore
import RealTimeFinData from '../assets/svg/features/RealTimeFinData.inline.svg';
// @ts-ignore
import DocumentManagement from '../assets/svg/features/DocumentManagement.inline.svg';
// @ts-ignore
import PeaceOfMind from '../assets/svg/features/PeaceOfMind.inline.svg';
// @ts-ignore
import TaxSavings from '../assets/svg/features/TaxSavings.inline.svg';
// @ts-ignore
import AuditProtection from '../assets/svg/features/AuditProtection.inline.svg';

const FeaturesGrid: React.FC = () => {
  return (
    <div className='features-grid__container mx-auto mx-0 flex flex-col justify-center mb-6'>
      <ul className='features-grid__row'>
        <li className='features-grid'>
          <UpfrontPricing />
          <h4>UPFRONT PRICING</h4>
          <p>No hidden fees. We design a monthly package just for you.</p>
        </li>
        <li className='features-grid'>
          <UnlimitedSupport />
          <h4>UNLIMITED SUPPORT</h4>
          <p>Your accounting team and CPA are there whenever you need it.</p>
        </li>
        <li className='features-grid'>
          <Automation />
          <h4>AUTOMATION</h4>
          <p>Eliminate redundant and repetitive tasks.</p>
        </li>
      </ul>
      <ul className='features-grid__row'>
        <li className='features-grid'>
          <TaxSavings />
          <h4>TAX SAVINGS</h4>
          <p>You’ll never miss a tax deduction or tax credit.</p>
        </li>
        <li className='features-grid'>
          <AuditProtection />
          <h4>AUDIT PROTECTION</h4>
          <p>We audit proof your records, so you never lose an audit.</p>
        </li>
        <li className='features-grid'>
          <PeaceOfMind />
          <h4>PEACE OF MIND</h4>
          <p>Knowing you are in good hands.</p>
        </li>
      </ul>
    </div>
  );
};

export default FeaturesGrid;
